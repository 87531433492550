import React from "react"
import { css } from "@emotion/react"
import ChevronRight from "../assets/svg/chevron-right.svg"
import { radius, fontStyle, color } from "../styles/theme"

export const ButtonDropdown = ({
  active,
  title,
  trigger,
  darkFooter,
  ...rest
}) => {
  return (
    <button
      onClick={trigger}
      css={css`
        background: ${darkFooter ? color.gray900 : "black"};
        border-radius: ${radius.small};
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.5em 1em;
        border: none;
        width: 100%;
        cursor: pointer;
      `}
    >
      <div
        css={css`
          overflow: hidden;
        `}
      >
        <p
          css={[
            fontStyle.dropdown.title,
            css`
              color: white;
              padding: 0;
              margin: 0;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              display: inline-block;
              text-overflow: ellipsis;
              max-width: 100%;
              line-height: 1;
            `,
          ]}
        >
          {title}
        </p>
      </div>
      <div
        css={css`
          height: 1.125rem;
          margin-left: 1em;
        `}
      >
        <ChevronRight
          css={css`
            fill: white;
            height: 100%;
            width: auto;
            transform: ${`rotate(${active ? "90" : "0"}deg)`};
            transition: transform ease-in-out 0.3s;
          `}
        />
      </div>
    </button>
  )
}

export default ButtonDropdown
