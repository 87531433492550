import React from "react"
import LinkedinLogo from "../../components/defaults/assets/svg/linkedin.svg"
import TwitterLogo from "../../components/defaults/assets/svg/twitter.svg"
import InstagramLogo from "../../components/defaults/assets/svg/instagram.svg"
import YoutubeLogo from "../../components/defaults/assets/svg/youtube.svg"

const general = {
  title: `EXPO2022: Driving Open Innovation on the Autobahn | STARTUP AUTOBAHN`,
  short: "EXPO2022",
  description: `Welcome to the most relevant conference for Corporate-Startup Collaboration in the field of mobility and beyond.`,
  author: `@startupautobahn`,
  // cononicalUrl: `https://innovationchallenge.pnp.tc`,
  // siteUrl: `https://innovationchallenge.pnp.tc`,
  // image: ``,
  // year: `2022`,
  // organization: {
  //   name: `STARTUP AUTOBAHN powered by Plug and Play`,
  //   url: "https://startup-autobahn.com",
  //   logo: "",
  // },
  // contact: {
  //   name: "Milan Wurth",
  //   email: "m.wurth@pnptc.com",
  //   picture: "",
  // },
  // application: {
  //   url: "https://plugandplaytechcenter.bamboohr.com/jobs/view.php?id=191",
  // },
  social: [
    {
      name: "Linkedin",
      url: "https://linkedin.com/company/10781616",
      icon: <LinkedinLogo />,
    },
    {
      name: "Twitter",
      user: "@startupautobahn",
      url: "https://twitter.com/@startupautobahn",
      icon: <TwitterLogo />,
    },
    {
      name: "Instagram",
      url: "https://www.instagram.com/startupautobahn/",
      icon: <InstagramLogo />,
    },
    {
      name: "Youtube",
      url: "https://www.youtube.com/channel/UCRvI70vBDeU4vaU0pz_ylGg",
      icon: <YoutubeLogo />,
    },
  ],
}

export default general
