import React from "react"

import {
  Adac,
  Agc,
  Amber,
  Arena2036,
  Basf,
  Bosch,
  Bridgestone,
  Bp,
  Ceat,
  Dpdhl,
  Dxc,
  Eberspaecher,
  Forvia,
  Gf,
  Grupoantolin,
  Hero,
  Huf,
  Hyundai,
  Itt,
  Linde,
  Maxion,
  Mercedesbenz,
  Motherson,
  Murata,
  Novelis,
  Nxp,
  Plasticomnium,
  Pnp,
  Porsche,
  Schaeffler,
  Sekisui,
  St,
  Togg,
  Unistuttgart,
  Webasto,
  Zf,
} from "../defaults/assets/logos/partnerlogos/index"

const PartnerIcons = {
  recrqOLsoltTwoNDd: Adac,
  recGIetpK5nI7pnP4: Agc,
  recUQ85EkMdv6NDOy: Amber,
  recehPENwiLYngZuO: Arena2036,
  rec3Su70vcXEDqJgy: Basf,
  recS3IZVFQHO2bElS: Bosch,
  recGP001hxCadAHQ6: Bp,
  recXLWIkRFS8pQWfO: Bridgestone,
  recmYYkYuB5jX8Qvs: Ceat,
  recVBr0C2vlN9YJsp: Dpdhl,
  recNeWcSPkmbfEV38: Dxc,
  recb5npkDZOXd5dxm: Eberspaecher,
  reczd6zgGQHrA5kzw: Forvia,
  recEaLRw9WH5ibIUn: Gf,
  recA4iAdPABTfaDuu: Grupoantolin,
  recvnuqsMhSLq4lvM: Hero,
  rec7gaiNi0aqzDnh0: Huf,
  rechPUrpm2RZiWKlC: Hyundai,
  recv8x2old29v7rKE: Itt,
  recmpsrIDicvjmKle: Linde,
  rec8tYJl8hvf1Qj9g: Maxion,
  recKTYFBG7McYzcxx: Mercedesbenz,
  recsUWUruV6mdY8he: Motherson,
  recyCjg7LVob2TWSp: Murata,
  recTsPsKsQfLROX0G: Novelis,
  recgmF7h1XQ0HsgU7: Nxp,
  rechymTMDjI0E6EZG: Plasticomnium,
  reckQuH91dXNoiZkn: Pnp,
  reczyoDgZtXUi93Gq: Porsche,
  recwN5iW1hH4Hdnwa: Schaeffler,
  recQgEPPJOB3CaxRK: Sekisui,
  recs7YWAQNKb34Whe: St,
  recIQSNQ3xKEgJ9dn: Togg,
  rec0LC2U9qa5FnF2g: Unistuttgart,
  recZOuGLG0RruiT6K: Webasto,
  recuDB6chQyp7MOM1: Zf,
}

const PartnerLogo = ({ id, className }) => {
  let Icon = undefined

  if (PartnerIcons[id] !== undefined) {
    Icon = PartnerIcons[id]
  } else {
    Icon = Pnp
  }

  return <>{Icon ? <Icon className={className} /> : ""}</>
}

export default PartnerLogo
