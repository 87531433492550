import React from "react"

import AccureLogo from "./accure_pri_blk_rgb.svg"
import ActyxLogo from "./actyx_pri_blk_rgb.svg"
import AndchargeLogo from "./andcharge_pri_blk_rgb.svg"
import AsvinLogo from "./asvin_pri_blk_rgb.svg"
import AtlatecLogo from "./atlatec_pri_blk_rgb.svg"
import BarewaysLogo from "./bareways_pri_blk_rgb.svg"
import BeeplanetLogo from "./beeplanet_pri_blk_rgb.svg"
import BrighteraiLogo from "./brighterai_pri_blk_rgb.svg"
import Brodmann17Logo from "./brodmann17_pri_blk_rgb.svg"
import CelusLogo from "./celus_pri_blk_rgb.svg"
import CirquaLogo from "./cirqua_pri_blk_rgb.svg"
import CoolingphotonicsLogo from "./coolingphotonics_pri_blk_rgb.svg"
import CovisionLogo from "./covision_pri_blk_rgb.svg"
import CybellumLogo from "./cybellum_pri_blk_rgb.svg"
import DeepfineLogo from "./deepfine_pri_blk_rgb.svg"
import DeliciousdataLogo from "./deliciousdata_pri_blk_rgb.svg"
import DesignledLogo from "./designled_pri_blk_rgb.svg"
import DrishtiLogo from "./drishti_pri_blk_rgb.svg"
import DycotecLogo from "./dycotec_pri_blk_rgb.svg"
import EdgelesssystemsLogo from "./edgelesssystems_pri_blk_rgb.svg"
import EmboldLogo from "./embold_pri_blk_rgb.svg"
import EndelLogo from "./endel_pri_blk_rgb.svg"
import ExcelforeLogo from "./excelfore_pri_blk_rgb.svg"
import FlipLogo from "./flip_pri_blk_rgb.svg"
import ForciotLogo from "./forciot_pri_wht_rgb.svg"
import FourSilenceLogo from "./foursilence_pri_blk_rgb.svg"
import GermanautolabsLogo from "./german_autolabs_pri_blk_rgb.svg"
import GestoosLogo from "./gestoos_pri_blk_rgb.svg"
import GriiipLogo from "./griiip_pri_blk_rgb.svg"
import HyperganicLogo from "./hyperganic_pri_blk_rgb.svg"
import HypermileLogo from "./hypermile_pri_blk_rgb.svg"
import IdentifaiLogo from "./identifai_pri_blk_rgb.svg"
import KontrolLogo from "./kontrol_pri_blk_rgb.svg"
import MaaindLogo from "./maaind_pri_wht_rgb.svg"
import MakersiteLogo from "./makersite_pri_blk_rgb.svg"
import MercanisLogo from "./mercanis_pri_blk_rgb.svg"
import MonolithLogo from "./monolith_pri_blk_rgb.svg"
import MopicLogo from "./mopic_pri_blk_rgb.svg"
import NeurocontrolsLogo from "./neurocontrols_pri_blk_rgb.svg"
import NodarLogo from "./nodar_pri_blk_rgb.svg"
import NoderoboticsLogo from "./noderobotics_pri_blk_rgb.svg"
import NxrtLogo from "./nxrt_pri_blk_rgb.svg"
import OculavisLogo from "./oculavis_pri_blk_rgb.svg"
import PeakboardLogo from "./peakboard_pri_blk_rgb.svg"
import PlancoLogo from "./planco_pri_blk_rgb.svg"
import PolymertalLogo from "./polymertal_pri_blk_rgb.svg"
import PreactLogo from "./preact_pri_blk_rgb.svg"
import RoadtravelLogo from "./roadtravel_pri_blk_rgb.svg"
import RoutekonnectLogo from "./routekonnect_pri_blk_rgb.svg"
import S1SevenLogo from "./s1seven_pri_blk_rgb.svg"
import SenselLogo from "./sensel_pri_blk_rgb.svg"
import SensemoreLogo from "./sensemore_pri_blk_rgb.svg"
import SevenbelLogo from "./sevenbel_pri_blk_rgb.svg"
import SpinqueLogo from "./spinque_pri_blk_rgb.svg"
import SparetechLogo from "./sparetech_pri_blk_rgb.svg"
import SpreadaiLogo from "./spread_pri_blk_rgb.svg"
import SwipeguideLogo from "./swipeguide_pri_blk_rgb.svg"
import TerranetLogo from "./terranet_pri_wht_rgb.svg"
import TextiaLogo from "./textia_pri_blk_rgb.svg"
import TrieyeLogo from "./trieye_pri_blk_rgb.svg"
import TruckooLogo from "./truckoo_pri_blk_rgb.svg"
import UbqLogo from "./ubq_pri_blk_rgb.svg"
import VencortexLogo from "./vencortex_pri_blk_rgb.svg"
import VentusLogo from "./ventus_pri_blk_rgb.svg"
import VenuexLogo from "./venuex_pri_blk_rgb.svg"
import VianovaLogo from "./vianova_pri_blk_rgb.svg"
import ViezoLogo from "./viezo_pri_blk_rgb.svg"
import WayaheadLogo from "./wayahead_pri_blk_rgb.svg"
import WheelmeLogo from "./wheelme_pri_blk_rgb.svg"
import YoldaLogo from "./yolda_pri_blk_rgb.svg"
import YuriLogo from "./yuri_pri_blk_rgb.svg"

export const Foursilence = () => {
  return <FourSilenceLogo />
}
export const Accure = () => {
  return <AccureLogo />
}
export const Actyx = () => {
  return <ActyxLogo />
}
export const Andcharge = () => {
  return <AndchargeLogo />
}
export const Asvin = () => {
  return <AsvinLogo />
}
export const Atlatec = () => {
  return <AtlatecLogo />
}
export const Bareways = () => {
  return <BarewaysLogo />
}
export const Beeplanet = () => {
  return <BeeplanetLogo />
}
export const Brighterai = () => {
  return <BrighteraiLogo />
}
export const Brodmann17 = () => {
  return <Brodmann17Logo />
}
export const Celus = () => {
  return <CelusLogo />
}
export const Cirqua = () => {
  return <CirquaLogo />
}
export const Coolingphotonics = () => {
  return <CoolingphotonicsLogo />
}
export const Covision = () => {
  return <CovisionLogo />
}
export const Cybellum = () => {
  return <CybellumLogo />
}
export const Deepfine = () => {
  return <DeepfineLogo />
}
export const Deliciousdata = () => {
  return <DeliciousdataLogo />
}
export const Designled = () => {
  return <DesignledLogo />
}
export const Drishti = () => {
  return <DrishtiLogo />
}
export const Dycotec = () => {
  return <DycotecLogo />
}
export const Forciot = () => {
  return <ForciotLogo />
}
export const Edgelesssystems = () => {
  return <EdgelesssystemsLogo />
}
export const Embold = () => {
  return <EmboldLogo />
}
export const Endel = () => {
  return <EndelLogo />
}
export const Excelfore = () => {
  return <ExcelforeLogo />
}
export const Flip = () => {
  return <FlipLogo />
}
export const Germanautolabs = () => {
  return <GermanautolabsLogo />
}
export const Gestoos = () => {
  return <GestoosLogo />
}
export const Griiip = () => {
  return <GriiipLogo />
}
export const Hyperganic = () => {
  return <HyperganicLogo />
}
export const Hypermile = () => {
  return <HypermileLogo />
}
export const Identifai = () => {
  return <IdentifaiLogo />
}
export const Kontrol = () => {
  return <KontrolLogo />
}
export const Maaind = () => {
  return <MaaindLogo />
}
export const Makersite = () => {
  return <MakersiteLogo />
}
export const Mercanis = () => {
  return <MercanisLogo />
}
export const Monolith = () => {
  return <MonolithLogo />
}
export const Mopic = () => {
  return <MopicLogo />
}
export const Neurocontrols = () => {
  return <NeurocontrolsLogo />
}
export const Noderobotics = () => {
  return <NoderoboticsLogo />
}
export const Nodar = () => {
  return <NodarLogo />
}
export const Nxrt = () => {
  return <NxrtLogo />
}
export const Oculavis = () => {
  return <OculavisLogo />
}
export const Peakboard = () => {
  return <PeakboardLogo />
}
export const Planco = () => {
  return <PlancoLogo />
}
export const Polymertal = () => {
  return <PolymertalLogo />
}
export const Preact = () => {
  return <PreactLogo />
}
export const Roadtravel = () => {
  return <RoadtravelLogo />
}
export const Routekonnect = () => {
  return <RoutekonnectLogo />
}
export const S1seven = () => {
  return <S1SevenLogo />
}
export const Sensel = () => {
  return <SenselLogo />
}
export const Sensemore = () => {
  return <SensemoreLogo />
}
export const Sevenbel = () => {
  return <SevenbelLogo />
}
export const Sparetech = () => {
  return <SparetechLogo />
}
export const Spinque = () => {
  return <SpinqueLogo />
}
export const Spreadai = () => {
  return <SpreadaiLogo />
}
export const Swipeguide = () => {
  return <SwipeguideLogo />
}
export const Trieye = () => {
  return <TrieyeLogo />
}
export const Truckoo = () => {
  return <TruckooLogo />
}
export const Terranet = () => {
  return <TerranetLogo />
}
export const Textia = () => {
  return <TextiaLogo />
}
export const Ubq = () => {
  return <UbqLogo />
}
export const Vencortex = () => {
  return <VencortexLogo />
}
export const Ventus = () => {
  return <VentusLogo />
}
export const Venuex = () => {
  return <VenuexLogo />
}
export const Vianova = () => {
  return <VianovaLogo />
}
export const Viezo = () => {
  return <ViezoLogo />
}
export const Wayahead = () => {
  return <WayaheadLogo />
}
export const Wheelme = () => {
  return <WheelmeLogo />
}
export const Yolda = () => {
  return <YoldaLogo />
}
export const Yuri = () => {
  return <YuriLogo />
}
