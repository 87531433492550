import React from "react"
import { css, Global } from "@emotion/react"
import mq from "../components/defaults/styles/mediaquerys"

// import Footer from "../components/defaults/footer/footer"
import "@fontsource/inter/latin.css"
import Seo from "../components/SEO/seo"
import Analytics from "../components/analytics"
import PlueNavigation from "../components/defaults/header/plue_navigation"
import PlueNavigationMobile from "../components/defaults/header/plue_navigation_mobile"
import PlueFooter from "../components/defaults/footer/plue_footer"

const Layout = ({ children, seo, ...props }) => {
  return (
    <>
      <Global
        styles={css`
          body {
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: antialiased;
            font-smoothing: antialiased;
          }
        `}
      ></Global>
      {!props.individualSEO ? <Seo {...seo} /> : ""}
      <PlueNavigation {...props} />
      <div>{children}</div>
      {/* <Footer /> */}
      <PlueFooter {...props} />
      <div
        css={css`
          height: 4rem;
          display: block;
          ${mq[1]} {
            display: none;
          }
        `}
      ></div>
      <PlueNavigationMobile />
      <Analytics />
    </>
  )
}

export default Layout
