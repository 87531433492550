import React, { useState, useRef } from "react"
import { css } from "@emotion/react"
import { fontStyle, color } from "../styles/theme"
import Link from "../../link"
import ChevronRight from "../assets/svg/chevron-right.svg"

export const DropdownItem = ({ title, items, forceOpen }) => {
  const [active, setActive] = useState(false)
  const [height, setHeight] = useState(0)
  const dropdownContent = useRef(null)

  const toggleDropdown = () => {
    setActive(current => !current)
    if (active) {
      setHeight(0)
    } else {
      setHeight(dropdownContent.current.scrollHeight)
    }
  }

  return (
    <>
      <button
        onClick={!forceOpen ? toggleDropdown : null}
        css={[
          fontStyle.footer.item,
          css`
            padding: 0.5em 0 0.5em 1rem;
            background: none;
            border: none;
            display: flex;
            align-items: baseline;
            line-height: 1.4em;
            position: relative;
            cursor: ${forceOpen ? "default" : "pointer"};
            text-align: left;
          `,
        ]}
      >
        <div
          css={css`
            display: inline;
            width: auto;
            display: flex;
            position: absolute;
            left: 0;
          `}
        >
          <ChevronRight
            css={css`
              height: 1em;
              width: auto;
              //position: relative;
              //top: 50%;
              fill: ${color.gray500};
              transform: ${`rotate(${active || forceOpen ? "90" : "0"}deg)`};
              transition: transform ease-in-out 0.3s;
            `}
          />
        </div>
        {title}
      </button>
      <DropdownList
        items={items}
        refProp={dropdownContent}
        dropdownHeight={!forceOpen ? height : "unset"}
      />
    </>
  )
}

const DropdownList = ({ className, items, refProp, dropdownHeight }) => {
  const DefaultItemIndented = ({ title, url }) => {
    return (
      <DefaultItem
        title={title}
        url={url}
        css={css`
          padding-left: 1rem;
          border-left: 1px solid ${color.gray300};
          margin-left: 2px;
        `}
      />
    )
  }

  return (
    <div
      ref={refProp}
      className={className}
      css={css`
        height: ${dropdownHeight + "px"};
        overflow: hidden;
        transition: height ease-in-out 0.3s;
      `}
    >
      <div
        css={css`
          margin: 0.25em 0 1em;
        `}
      >
        {items.map((e, index) => (
          <React.Fragment key={index}>
            {e.title && e.url ? (
              <DefaultItemIndented key={index} title={e.title} url={e.url} />
            ) : null}
            {e.items && e.items.length > 0
              ? e.items.map((j, index) => (
                  <DefaultItemIndented
                    key={index}
                    title={
                      e.title && j.title
                        ? e.title + " / " + j.title
                        : e.title
                        ? e.title + " / " + (index + 1)
                        : "Undefined Link"
                    }
                    url={j.url}
                  />
                ))
              : null}
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}

const DefaultItem = ({ className, title, url }) => {
  return (
    <Link
      className={className}
      css={[
        fontStyle.footer.item,
        css`
          display: block;
          text-decoration: none;
          padding: 0.5em 0;
        `,
      ]}
      to={url}
    >
      {title}
    </Link>
  )
}

export const DropdownInner = ({ data, forceOpen }) => {
  return (
    <div>
      {data.map((e, index) =>
        e.title && e.url ? (
          <DefaultItem key={index} title={e.title} url={e.url} />
        ) : e.title &&
          e.items &&
          e.items.length > 0 &&
          e.items.some(y => (y.url ? true : false)) ? (
          <DropdownItem
            key={index}
            title={e.title}
            items={e.items}
            forceOpen={forceOpen}
          />
        ) : null
      )}
    </div>
  )
}

export default DropdownInner
