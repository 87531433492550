import React from "react"
import { css } from "@emotion/react"
import { useRegistration } from "../services/typeformRegistration"
import { color } from "../components/defaults/styles/theme"
import { IconButtonBtn } from "../components/defaults/styles/elements"
import Link from "../components/link"
import Business from "../components/defaults/assets/svg/business.svg"
import Bulb from "../components/defaults/assets/svg/bulb.svg"
import Users from "../components/defaults/assets/svg/users.svg"
import Agenda from "../components/defaults/assets/svg/agenda.svg"
import Archive from "../components/defaults/assets/svg/archive.svg"
import Ticket from "../components/defaults/assets/svg/ticket.svg"
import Play from "../components/defaults/assets/svg/play.svg"
import Info from "../components/defaults/assets/svg/info.svg"
import Rearview from "../components/defaults/assets/svg/rearview.svg"
import Flags from "../data/raw/flags"

const RegisterButton = ({ className, ...rest }) => {
  const { registerButton, toggle } = useRegistration()

  return (
    <IconButtonBtn
      className={className}
      {...rest}
      css={css`
        padding: 0.35rem 0.8rem;
        letter-spacing: 0.025em;
        font-weight: 600;
      `}
      onClick={toggle}
      name={registerButton.buttonText}
    />
  )
}

const PreheaderContent = () => {
  const { registerButton, toggle } = useRegistration()
  return (
    <>
      <p>
        <span
          css={css`
            font-weight: 600;
          `}
        >
          {Flags.features.live
            ? Flags.settings.live.title
            : Flags.features.rearview
            ? "Rearview"
            : "July 07, 2022:"}
        </span>{" "}
        {Flags.features.live
          ? "Join the STARTUP AUTOBAHN powered by Plug and Play EXPO2022 broadcast"
          : Flags.features.rearview
          ? "Checkout the recordings of STARTUP AUTOBAHN EXPO2022."
          : "Register now for the online broadcast or apply for an on-site ticket."}{" "}
        {Flags.features.live ? (
          <Link
            css={css`
              font-weight: 600;
            `}
            to={Flags.settings.live.destination}
          >
            Join Now
          </Link>
        ) : Flags.features.main_registration ? (
          <button
            css={css`
              border: none;
              background: none;
              font-weight: 600;
              cursor: pointer;
              padding: 0;
            `}
            onClick={toggle}
          >
            {registerButton.buttonText}
          </button>
        ) : Flags.features.rearview ? (
          <Link
            css={css`
              font-weight: 600;
            `}
            to={`/rearview`}
          >
            Rewatch
          </Link>
        ) : null}
      </p>
    </>
  )
}

const navigation = {
  preheader: {
    info: {
      block: Flags.features.live ? "LIVE • EXPO2022" : "EXPO2022",
      content: <PreheaderContent />,
    },
    navigation: [
      {
        title: "startup-autobahn.com",
        url: "https://startup-autobahn.com",
      },
      {
        title: "Contact",
        url: "mailto:hannah@pnptc.com",
      },
    ],
  },
  header: {
    navigation: [
      // Explanation of the navigation element
      // ______________
      // There are two different variants of clickable elements that currently can be added.
      // A simple Link to a single location will be added like below:
      //
      // {
      //   title: "This is the displayed title of the element",
      //   url: "And this is the location that will be opened up upon click."
      // }
      //
      // The second variation is a dropdown menu. A dropdown can include unlimited sub-elements
      // including "title", "teaser", "url" and a third level of links that is basically a list
      // with various destinations.
      // A maxed out and fully configured section would look like this:
      // {
      //   title: "A Dropdown",
      //   items: [
      //     {
      //       title: "This is the First Element of the Dropdown",
      //       teaser: "This Element also includes a teaser sentence and is clickable itself because it features a url",
      //       url: "That is the destination",
      //       background: red;
      //     },
      //     {
      //       title: "This is the Second Element of the Dropdown",
      //       teaser: "This Element features not just a url but also a nested list of further locations",
      //       url: "That is the destination",
      //       items: [
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com"
      //         },
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com"
      //         },
      //         {
      //           title: "The sublist can only feature a title and the destination url",
      //           url: "https://bastiankroggel.com"
      //         },
      //       ]
      //     },
      //   ]
      // }
      {
        title: "Startups",
        url: "/startups",
        icon: <Bulb />,
      },
      {
        title: "Partners",
        url: "/partners",
        icon: <Business />,
      },
      {
        title: "Agenda",
        icon: <Agenda />,
        items: [
          {
            title: "Complete Agenda",
            teaser:
              "Checkout the entire agenda including all agenda slots of EXPO2022.",
            url: "/agenda",
            background: color.plue25,
          },
          {
            title: "Stage One",
            teaser: "aka Future of Mobility & Sustainability",
            url: "/agenda/stage/one",
          },
          {
            title: "Stage Two",
            teaser: "aka Production & Enterprise",
            url: "/agenda/stage/two",
          },
          {
            title: "Event more to explorer",
            teaser:
              "Stage One? Stage Two? — there is even more. EXPO2022 also features an outside stage as well as Breakout Sessions and Innovation Walks — ready for STARTUP AUTOBAHN and beyond?",
            items: [
              { title: "Outside Stage", url: "/agenda/stage/three" },
              { title: "Side Events", url: "/agenda/stage/four" },
            ],
          },
        ],
      },
      {
        title: "People",
        icon: <Users />,
        items: [
          {
            title: "All people",
            url: "/people/all",
            teaser: "Find out more about who is attending EXPO2022",
          },
          {
            title: "Speaker",
            teaser: "The people on our EXPO2022 stages.",
            url: "/people/speakers",
          },
          {
            title: "Corporate Champions",
            url: "/people/champions",
            teaser:
              "Everything about our the STARTUP AUTOBAHN Champions of our partner corporations.",
          },
          {
            title: "Startup Employees",
            teaser:
              "Check out the brilliant minds behind the STARTUP AUTOBAHN startups.",
            url: "/people/startupemployees",
          },
          {
            title: "EXPO Management",
            url: "/people/ops",
            teaser:
              "In case you were wondering who is behind the scenes at EXPO2022",
          },
        ],
      },
      {
        title: "Rearview",
        icon: <Rearview />,
        url: "/rearview",
      },
      {
        title: "About",
        icon: <Info />,
        iconStroke: true,
        items: [
          // {
          //   title: "Press Release(s)",
          //   teaser:
          //     "Press Releases of Partners and Startups can be found right in their respective Profiles.",
          //   items: [
          //     {
          //       title: "Open Partner Profiles",
          //       url: "/partners",
          //     },
          //     {
          //       title: "Open Startup Profiles",
          //       url: "/startups",
          //     },
          //   ],
          // },
          {
            title: "Press Release(s)",
            teaser:
              "Find below attached the official press releases for EXPO2022 in english as well as german.",
            items: [
              {
                title: "Pressrelease English",
                url: "/media/Press_Release _Startup_Autobahn_ EXPO2022_EN.pdf",
              },
              {
                title: "Pressrelease German",
                url: "/media/Pressemeldung_Startup_Autobahn_%20EXPO2022_DE.pdf",
              },
              {
                title: "Presskit English",
                url: "/media/STARTUP_AUTOBAHN_PRESS_KIT_EXPO2022_EN.pdf",
              },
              {
                title: "Presskit German",
                url: "/media/STARTUP_AUTOBAHN_PRESS_KIT_EXPO2022_DE.pdf",
              },
            ],
          },
          {
            title: "STARTUP AUTOBAHN website",
            url: "https://startup-autobahn.com",
            teaser:
              "If you would like to get to know more about STARTUP AUTOBAHN, its partners, startups and program feel free to head over to the official website.",
          },
          {
            title: "Archive",
            url: "/archive",
            teaser:
              "We invite you to check out our past EXPOs and to go back in time and take a look at 6-years of STARTUP AUTOBAHN together with us.",
          },
          {
            title: "Miscellaneous",
            teaser: "Just in case you are interested & for your information.",
            items: [
              {
                title: "Imprint",
                url: "/imprint",
              },
              {
                title: "Privacy Policy & Legal",
                url: "/privacy-policy",
              },
              {
                title: "Sitemap",
                url: "/sitemap-index.xml",
              },
            ],
          },
        ],
      },
    ],
    cta: [
      {
        content: <RegisterButton />,
        type: "raw",
        active:
          Flags.features.main_registration && !Flags.features.live
            ? true
            : false,
      },
      {
        title: "Rearview",
        url: "/rearview",
        type: "link",
        active: Flags.features.rearview ? true : false,
      },
      {
        title: "Join Livestream",
        url: Flags.settings.live.destination,
        type: "link",
        customColor: {
          light: color.plue_rec,
          dark: color.plue_rec,
        },
        active: Flags.features.live ? true : false,
      },
      // {
      //   title: "bastiankroggel.com",
      //   url: "https://bastiankroggel.com",
      //   type: "link"
      // },
    ],
  },
  mobile: {
    intro: {
      content: Flags.features.live
        ? "<p>Come in, we’re live.</p><p>In order to join our EXPO2022 livestream just click on the button below and you will be guided to the right destination."
        : Flags.features.rearview
        ? "<p>To rewatch our EXPO2022 click on the button below and select the sessions and streams you would like to relive.</p>"
        : "<p>To safe a spot and to don‘t miss out on any updates regarding EXPO2022 click on the button below to apply for one of the on-location tickets or to safe your spot for the online broadcast.</p>",
      // button: {
      //   title: "Register Now",
      //   icon: <Bulb />,
      //   url: "https://bastiankroggel.com",
      // }
      buttons: [
        {
          title: "Join the livestream now!",
          url: Flags.settings.live.destination,
          icon: <Play />,
          customColor: {
            background: color.plue_rec,
          },
          active: Flags.features.live ? true : false,
        },
        {
          raw: (
            <RegisterButton
              icon={<Ticket />}
              css={css`
                width: 100%;
                display: inline-flex;
                align-items: center;
                text-align: left;
                svg {
                  fill: white;
                  stroke: none;
                }
                &:before {
                  background: ${color.plue_light};
                }
              `}
            />
          ),
          active:
            Flags.features.main_registration && !Flags.features.live
              ? true
              : false,
        },
        {
          title: "Check out the EXPO2022 Rearview",
          url: "/rearview",
          icon: <Rearview />,
          active: Flags.features.rearview ? true : false,
        },
      ],
    },
    navigation: [
      {
        title: "People",
        url: "/people/all",
        icon: <Users />,
      },
      {
        title: "Startups",
        url: "/startups",
        icon: <Bulb />,
      },
      {
        title: "Partners",
        icon: <Business />,
        url: "/partners",
      },
      {
        title: "Rearview",
        icon: <Rearview />,
        url: "/rearview",
      },
      // {
      //   title: "Agenda",
      //   icon: <Agenda />,
      //   url: "/agenda/",
      // },
      // {
      //   title: "Archive",
      //   icon: <Archive />,
      //   url: "/archive",
      // },
    ],
  },
  footer: {
    info: {
      footer_title: "Plug and Play Germany GmbH | Stuttgart",
      copyright: `2022 Plug & Play Germany GmbH`,
      website: {
        title: "plugandplaytechcenter.com/startup-autobahn/",
        url: "https://plugandplaytechcenter.com/startup-autobahn/",
      },
    },
    navigation: [
      // Explanation of footer navigation
      // -----
      // The footer consists of multiple columns which form the first level of elements the navigation consists of
      //
      // {
      //    header: "First Level"
      //    items: []
      // }
      // Similar to header navigation there are two variations of navigation elements available, which are children of the items array above.
      // A simple object containing "title" and "url" will create a single entry
      //
      // {
      //   title: "First Link"
      //   url: "https://url-to-first-link.com"
      // }
      //
      // A object with additional children further more allows to create an expandable element which can hold unlimited sub items under
      // a single headline
      //
      // {
      //   header: "Multiple Links",
      //   items: [
      //     {
      //       title: "First Item",
      //       url: "https://test.com",
      //     },
      //     {
      //       title: "Second Item",
      //       url: "https://test.com",
      //     },
      //   ]
      // }
      {
        header: "Links",
        items: [
          {
            title: "EXPO2022 Rearview",
            url: "/rearview",
          },
          {
            title: "Jobs at Plug and Play",
            url: "https://plugandplaytechcenter.bamboohr.com/jobs/",
          },
          {
            title: "Premaster Program",
            url: "https://premaster.pnptc.career/",
          },
          {
            title: "Image Database",
            url: "https://pictures.startup-autobahn.com/",
          },
          {
            title: "About STARTUP AUTOBAHN",
            url: "https://startup-autobahn.com",
          },
        ],
      },
      {
        header: "Legal and Documents",
        items: [
          {
            title: "Imprint and Legal",
            url: "/imprint",
          },
          {
            title: "Privacy and Security Policy",
            url: "/privacy-policy",
          },
          {
            title: "Sitemap",
            url: "/sitemap-index.xml",
          },
        ],
      },
    ],
  },
}

export default navigation
