import React from "react"
import { css } from "@emotion/react"
import { color } from "./defaults/styles/theme"
import {
  wrapper,
  TitleHeader,
  IconButton,
  IconButtonBtn,
} from "./defaults/styles/elements"
import mq from "./defaults/styles/mediaquerys"

const RegisterElement = ({ content }) => {
  const { title, icon, iconFill, description, destination, sub } = content

  if (sub?.icon?.icon) {
    sub["iconType"] = sub.icon.type
    sub["icon"] = sub.icon.icon
  }

  return (
    <div
      css={css`
        background: ${color.gray25};
        padding: 1.3rem;
        border-radius: 5px;
        max-width: 45rem;
        margin: 0 auto;
        z-index: 1;
        position: relative;
        ${mq[0]} {
          padding: 2rem;
        }
      `}
    >
      <div>
        <TitleHeader
          title={title}
          icon={icon}
          css={[
            css`
              padding: 0;
              h2 {
                font-size: 1.2em;
              }
              svg {
                stroke: white;
              }
            `,
            iconFill
              ? css`
                  stroke: none;
                  fill: white;
                `
              : null,
          ]}
        />
        <p
          css={css`
            font-size: 0.85rem;
          `}
        >
          {description}
        </p>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          flex: 1.4123 0 25%;
          flex-wrap: wrap;
          align-items: center;
          margin-top: 2em;
        `}
      >
        {destination?.trigger ? (
          <IconButtonBtn
            name={destination?.title}
            icon={destination?.icon}
            onClick={destination?.trigger}
            css={[
              css`
                display: flex;
                align-items: center;
                margin-top: 1em;
                font-weight: 600;
                &:before {
                  background: ${color.plue400};
                }
              `,
            ]}
          />
        ) : (
          <IconButton
            name={destination?.title}
            icon={destination?.icon}
            url={destination?.url}
            css={[
              css`
                display: flex;
                align-items: center;
                margin-top: 1em;
                font-weight: 600;
                &:before {
                  background: ${color.plue400};
                }
              `,
            ]}
          />
        )}
        <IconButton
          to={sub?.url}
          name={sub?.title}
          icon={sub?.icon}
          css={[
            css`
              display: flex;
              align-items: center;
              color: ${color.main_dark};
              padding-left: 0;
              margin-top: 1em;
              padding-left: 1em;
              margin-top: 1em;
              &:before {
                background: none;
              }
            `,
            sub.iconType && sub.iconType === "fill"
              ? css`
                  svg {
                    fill: ${color.main_dark};
                    stroke: none;
                  }
                `
              : css`
                  svg {
                    stroke: ${color.main_dark};
                    fill: none;
                  }
                `,
          ]}
        />
      </div>
    </div>
  )
}

const FooterBox = ({ content, ...props }) => {
  return (
    <>
      <section
        css={css`
          background: ${props.theme};
          position: relative;
          &:after {
            content: "";
            background: ${props.darkFooter ? props.theme : "white"};
            position: absolute;
            bottom: 0;
            left: 0;
            height: 25%;
            width: 100%;
            z-index: 0;
          }
          &:before {
            content: "";
            background: ${props.darkFooter ? "white" : color.main_dark};
            position: absolute;
            bottom: 25%;
            left: 0;
            height: 1px;
            width: 100%;
            opacity: ${props.darkFooter ? "0.25" : "0.08"};
            z-index: 0;
          }
        `}
      >
        <div css={[wrapper]}>
          <RegisterElement content={content} />
        </div>
      </section>
    </>
  )
}

export default FooterBox
