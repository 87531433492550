import React, { useState, useEffect } from "react"
// import RegisterNow from './registernow';
import FooterBox from "./template_footer_element"
import Flags from "../data/raw/flags"
import { isLoggedIn } from "../services/auth"
import Ticket from "../components/defaults/assets/svg/ticket.svg"
// import { Archive } from "../components/defaults/assets/svg/mobilenav"
import RearviewIcon from "../components/defaults/assets/svg/rearview.svg"
import Message from "../components/defaults/assets/svg/message.svg"
import { useRegistration } from "../services/typeformRegistration"

const GeneralFooter = ({ ...params }) => {
  const [loggedIn, setLoggedIn] = useState(false)
  const { registerButton, toggle } = useRegistration()

  const expo2023 = {
    title: "Get your tickets for our upcoming events",
    description:
      "Did you enjoy your attendance at EXPO2022 and would love to take more chances to get involved with the ecosystem? Or did you miss out on our most recent EXPO and are eager to prevent yourself from making the same mistake again? Apply now for one of the upcoming STARTUP AUTOBAHN events throughout the year or a chance to attend our EXPO in spring/summer 2023 as we bring together innovators from around the world, showcasing new technologies and party another round of successful STARTUP AUTOBAHN startups.",
    icon: <Ticket />,
    iconFill: true,
    destination: {
      title: registerButton.buttonText,
      //url: "https://pnpgermany.typeform.com/to/RB0nRVxD",
      //icon: <Ticket />,
      trigger: toggle,
    },
    sub: {
      title: "EXPO2022 Rearview",
      url: "/rearview",
      icon: {
        icon: <RearviewIcon />,
        type: "fill",
      },
    },
  }

  const expo2022press = {
    title: "Official EXPO2022 press release",
    description:
      "Interested in the STARTUP AUTOBAHN powered by EXPO2022 but not in the mood to follow the entire broadcast? You‘d like to get a written outline of the most important aspects of EXPO2022? We got you covered. Find below the offical EXPO2022 presskit (in english as well as german).",
    icon: <Message />,
    iconFill: true,
    destination: {
      title: "Presskit (EN)",
      url: "/media/STARTUP_AUTOBAHN_PRESS_KIT_EXPO2022_EN.pdf",
    },
    sub: {
      title: "Presskit (DE)",
      url: "/media/STARTUP_AUTOBAHN_PRESS_KIT_EXPO2022_DE.pdf",
    },
  }

  // const expo11 = {
  //   title: "Express interest for EXPO 11",
  //   description:
  //     "EXPO 10 might not be completely wrapped up yet, but we are already in preparation stage for our first big event in 2022. Apply now for your chance to participate the coming spring as we bring together innovators from around the world, showcasing new technologies and party another round of successfull STARTUP AUTOBAHN startups.",
  //   icon: <Ticket />,
  //   iconFill: true,
  //   destination: {
  //     title: "Apply now",
  //     url: "https://pnpgermany.typeform.com/to/RB0nRVxD",
  //     // icon: <Ticket />,
  //   },
  //   sub: {
  //     title: "Expo Rearview",
  //     url: "/archive",
  //     icon: <Archive />,
  //   },
  // }

  useEffect(() => {
    setLoggedIn(isLoggedIn())
  }, [])

  if (Flags.features.main_registration && !Flags.features.live) {
    return <FooterBox content={expo2022press} {...params} />
  } else {
    return <FooterBox content={expo2023} {...params} />
  }
}

export default GeneralFooter
