module.exports = {
  siteMetadata: {
    title: `EXPO2022: Driving Open Innovation on the Autobahn | STARTUP AUTOBAHN`,
    description: `Welcome to the most relevant conference for Corporate-Startup Collaboration in the field of mobility and beyond.`,
    author: `@startupautobahn`,
    cononicalUrl: `https://expo2022.pnptc.events`,
    siteUrl: `https://expo2022.pnptc.events`,
    social: {
      twitter: `@startupautobahn`,
      linkedin: `https://www.linkedin.com/company/startupautobahn`,
      instagram: `https://www.instagram.com/startupautobahn/`,
    },
    image: `https://expo2022.pnptc.events/img/expo2022thumbnail.jpg`,
    year: `2022`,
    organization: {
      name: `STARTUP AUTOBAHN powered by Plug and Play`,
      url: "https://startup-autobahn.com",
      logo: "",
    },
  },
  flags: {
    FAST_DEV: true,
    PRESERVE_WEBPACK_CACHE: true,
    PRESERVE_FILE_DOWNLOAD_CACHE: true,
  },
  plugins: [
    {
      resolve: `gatsby-plugin-google-gtag`,
      options: {
        trackingIds: [
          "G-HP2HFQJY1M", // Google Analytics / GA
        ],
        // This object gets passed directly to the gtag config command
        // This config will be shared across all trackingIds
        gtagConfig: {
          anonymize_ip: true,
          //cookie_expires: 0,
        },
        // This object is used for configuration specific to this plugin
        pluginConfig: {
          // Puts tracking script in the head instead of the body
          head: true,
          // Setting this parameter is also optional
          respectDNT: true,
          // Avoids sending pageview hits from custom paths
          //exclude: ["/preview/**", "/do-not-track/me/too/"],
        },
      },
    },
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-image`,
    `gatsby-plugin-sharp`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-transformer-remark`,
      options: {
        // CommonMark mode (default: true)
        commonmark: true,
        // Footnotes mode (default: true)
        footnotes: true,
        // Pedantic mode (default: true)
        pedantic: true,
        // GitHub Flavored Markdown mode (default: true)
        gfm: true,
        // Plugins configs
        plugins: [
          // gatsby-remark-relative-images must
          // go before gatsby-remark-images
          {
            resolve: `gatsby-remark-relative-images`,
          },
          {
            resolve: `gatsby-remark-images`,
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 590,
            },
          },
        ],
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `img`,
        path: `${__dirname}/static/img/`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `pressrelease`,
        path: `${__dirname}/src/data/pressrelease/`,
      },
    },
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `heroanimation`,
        path: `${__dirname}/static/media/hero_animation/`,
      },
    },
    `gatsby-transformer-json`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        path: `${__dirname}/src/data/raw/`,
        // plugins: [`gatsby-transformer-json`],
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `STARTUP AUTOBAHN EXPO2022: Driving Open Innovation on the Autobahn`,
        short_name: `EXPO2022`,
        start_url: `/`,
        background_color: `#000000`,
        theme_color: `#000000`,
        display: `browser`,
        icon: `static/img/expo9icon.png`, // This path is relative to the root of the site.
      },
    },
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
    {
      resolve: `gatsby-plugin-emotion`,
      options: {
        // Accepts all options defined by `babel-plugin-emotion` plugin.
      },
    },
    `gatsby-plugin-netlify-cms`,
    {
      resolve: `gatsby-plugin-typography`,
      options: {
        pathToConfigModule: `src/utils/typography`,
        omitGoogleFont: true,
      },
    },
    //`gatsby-remark-source-name`,
    // {
    //   resolve: `gatsby-plugin-create-client-paths`,
    //   options: { prefixes: [`/app/*`] },
    // },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /assets/,
          omitKeys: [
            "class",
            "xmlnsSvg",
            "inkscapeCurrentLayer",
            "inkscapeCx",
            "inkscapeCy",
            "inkscapeDocumentUnits",
            "inkscapePageopacity",
            "inkscapePageshadow",
            "inkscapeWindowHeight",
            "inkscapeWindowWidth",
            "inkscapeWindowX",
            "inkscapeWindowY",
            "inkscapeZoom",
            "xmlnsCc",
            "xmlnsDc",
            "xmlnsInkscape",
            "xmlnsRdf",
            "xmlnsSodipodi",
            "inkscapeExportFilename",
            "inkscapeOriginal",
            "inkscapePathEffect",
            "inkscapeRadius",
            "sodipodiType",
            "inkscapeGroupmode",
            "inkscapeLabel",
            "inkscapeExportXdpi",
            "inkscapeExportYdpi",
            "inkscapeVersion",
            "sodipodiDocname",
            "inkscapeConnectorCurvature",
            "inkscapeWindowMaximized",
            "fitMarginTop",
            "fitMarginRight",
            "fitMarginLeft",
            "fitMarginBottom",
          ],
        },
      },
    },
    {
      resolve: `gatsby-plugin-sitemap`,
      options: {
        output: "/",
        excludes: [`/inside/*`, `/roadmap`, `/agenda-old`],
      },
    },
    {
      resolve: `gatsby-source-airtable`,
      options: {
        apiKey: process.env.AIRTABLE_API_KEY,
        concurrency: 4,
        tables: [
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_ONE,
            queryName: "Partners",
            mapping: { description: "text/markdown", pressrelease: `fileNode` },
            tableLinks: [
              `users`,
              `testimonial_speaker`,
              `rearview`,
              `agenda`,
              `casestudies`,
            ], //users
            separateMapType: true,
            defaultValues: {
              testimonial_id: "",
            },
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_TWO,
            queryName: `Startups`,
            mapping: { description: "text/markdown", pressrelease: `fileNode` },
            tableLinks: [`users`, `rearview`, `agenda`, `casestudy`], //users
            separateMapType: true,
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_THREE,
            queryName: `People`,
            mapping: { image: `fileNode`, text: `text/markdown` },
            tableLinks: [
              `partner_company`,
              `startup_company`,
              `people_speaker`,
              `people_host`,
              `testimonial_speaker`,
              `rearview`,
            ],
            seperateMapType: true,
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_FOUR,
            queryName: `Agenda`,
            mapping: {
              agenda_description: "text/markdown",
              agenda_agenda: "text/markdown",
            },
            separateMapType: true,
            tableLinks: [
              `agenda_speaker`,
              `agenda_host`,
              `agenda_partners`,
              `agenda_startups`,
              `agenda_contact`,
              `agenda_casestudies`,
              `agenda_rearview`,
            ],
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_FIVE,
            queryName: `Rearview`,
            mapping: {
              rearview_description: `text/markdown`,
              rearview_thumbnail: `fileNode`,
            },
            separateMapType: true,
            tableLinks: [
              `rearview_startups`,
              `rearview_partners`,
              `rearview_people`,
              `rearview_agenda`,
            ],
            defaultValues: {
              password_protected: false,
            },
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_SIX,
            queryName: `FAQ`,
            mapping: {
              answer: `text/markdown`,
            },
            separateMapType: true,
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_SEVEN,
            queryName: `Updates`,
            mapping: {
              update_content: `text/markdown`,
              update_image: `fileNode`,
              update_attachement: `fileNode`,
            },
            tableLinks: [`update_author`],
            separateMapType: true,
          },
          {
            baseId: process.env.AIRTABLE_BASE,
            tableName: process.env.AIRTABLE_TABLE_NAME_EIGHT,
            queryName: `CaseStudies`,
            mapping: {
              cs_teaser: `text/markdown`,
              cs_file: `fileNode`,
              cs_thumbnail: `fileNode`,
            },
            tableLinks: [`cs_partner`, `cs_agenda`, `cs_startup`, `cs_contact`],
            separateMapType: true,
          },
        ],
      },
    },
    {
      resolve: `gatsby-background-image-es5`,
      options: {},
    },
    {
      resolve: `gatsby-plugin-netlify`,
      options: {
        headers: {}, // option to add more headers. `Link` headers are transformed by the below criteria
        allPageHeaders: [], // option to add headers for all pages. `Link` headers are transformed by the below criteria
        mergeSecurityHeaders: true, // boolean to turn off the default security headers
        mergeLinkHeaders: true, // boolean to turn off the default gatsby js headers
        mergeCachingHeaders: true, // boolean to turn off the default caching headers
        transformHeaders: (headers, path) => headers, // optional transform for manipulating headers under each path (e.g.sorting), etc.
        generateMatchPathRewrites: true, // boolean to turn off automatic creation of redirect rules for client only paths
      },
    },
  ],
}
