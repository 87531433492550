import React from "react"
import ExpoLogoSec from "../defaults/assets/logos/expo_sec_blk_rgb.svg"
import {
  Accure,
  Actyx,
  Andcharge,
  Asvin,
  Atlatec,
  Bareways,
  Brodmann17,
  Celus,
  Cirqua,
  Coolingphotonics,
  Covision,
  Deepfine,
  Edgelesssystems,
  Excelfore,
  Flip,
  Forciot,
  Foursilence,
  Griiip,
  Hyperganic,
  Hypermile,
  Identifai,
  Maaind,
  Makersite,
  Mercanis,
  Monolith,
  Mopic,
  Neurocontrols,
  Noderobotics,
  Nxrt,
  Oculavis,
  Roadtravel,
  Routekonnect,
  S1seven,
  Sensemore,
  Sevenbel,
  Sparetech,
  Spinque,
  Swipeguide,
  Trieye,
  Truckoo,
  Ubq,
  Ventus,
  Venuex,
  Vianova,
  Wayahead,
  Wheelme,
  Yolda,
} from "../defaults/assets/logos/startuplogos/index"

const StartupIcons = {
  rec0QC9f8KTw7TpqX: Foursilence,
  reci3juaGa0oSYczU: Accure,
  recHavE0MhJ0otmhy: Actyx,
  recAGXuQyC8niO0GX: Andcharge,
  recxQat1yV7FQXulA: Asvin,
  recejwfJVMmhXJdUT: Atlatec,
  recBfinNz3uimsYLi: Bareways,
  // b: Beeplanet,
  // rec9qFe30zZSHrtbw: Bestmile,
  // b: Brighterai,
  recw5OfJVJ2dVfSmm: Brodmann17,
  recv6S8lT4avlBiH7: Celus,
  rec9gSAssj8qj71dO: Cirqua,
  recN6WRz1Er6xj4Ig: Coolingphotonics,
  recWtAQNjXNzcmW0y: Covision,
  // b: Cybellum,
  recnWZAp0UhyN9FD0: Deepfine,
  // b: Deliciousdata,
  // b: Designled,
  // b: Drishti,
  // b: Dycotec,
  recJXS653xYFxkbCt: Edgelesssystems,
  reclWHOXPlhbcXJE4: Excelfore,
  recaCwZ2aaEPMHUaG: Flip,
  reck96PQxfU6wkay6: Forciot,
  // b: Embold,
  // b: Endel,
  // b: Excelfore,
  // b: Germanautolabs,
  // b: Gestoos,
  recilikB7FMSThwYI: Griiip,
  recldyeZPgyukxQAq: Hyperganic,
  rec2l0Gqoi4c323tg: Hypermile,
  recqUda5uXlU2FjXA: Identifai,
  // b: Kontrol,
  recMq09ym6NrPuxdy: Maaind,
  recL8bR55dUcWNFTu: Makersite,
  recEl4yvEH0knRNzq: Mercanis,
  recZ8qRqxcNSgT5eM: Monolith,
  rec6yxkJQwR0vxNqT: Mopic,
  recIaDz04tejo5ikE: Neurocontrols,
  recsCxmUh2LGBbNhn: Noderobotics,
  // b: Nodar,
  reco8gwiBfT9NrV0g: Nxrt,
  // b: Peakboard,
  // b: Polymertal,
  // b: Planco,
  // b: Preact,
  recY2H9p2trhPGpAc: Oculavis,
  recShKEkAQHWz3oXR: Roadtravel,
  recK046CrY7An1XQk: Routekonnect,
  recToge4RDpIpG02O: S1seven,
  // b: Sensel,
  recD0smyW5LFSDJVF: Sensemore,
  recZsbSCIYwaLuV7l: Sevenbel,
  rec9T706XuWfYjy90: Sparetech,
  recBIourAP0H9xucN: Spinque,
  // b: Spreadai,
  recpjVWkP2zV0zt62: Swipeguide,
  // b: Terranet,
  // b: Textia,
  recGipLG1sqyvRS98: Trieye,
  recDWbBf8GMd0kZih: Truckoo,
  recz6IWS05DQ7Mp1n: Ubq,
  // b: Vencortex,
  recvFLuLbyqsm51Pn: Ventus,
  rec6EMvpq2kPR68a4: Venuex,
  recAq9WlUXflky9ZS: Vianova,
  // b: Viezo,
  recx6otpEDkBJdglH: Wayahead,
  rec5jYg25xUzpod2z: Wheelme,
  // b: Yuri,
  recgHafEi8VHHiEjG: Yolda,
}

const StartupLogo = ({ id }) => {
  let Icon = undefined

  if (StartupIcons[id] !== undefined) {
    Icon = StartupIcons[id]
  } else {
    Icon = ExpoLogoSec
  }
  return <>{Icon ? <Icon /> : ""}</>
}

export default StartupLogo
